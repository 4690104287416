<template>
  <PageView :not-found="workItems_store.notFound">
    <UserAnalytics />

    <!-- Work Item Details View -->
    <div class="flex gap-2 h-full">
      <div class="h-full grow">
        <div class="flex items-center">
          <div
            class="flex w-full h-fit mb-3"
            :class="`${leftoverSpace >= 1280 ? 'flex-row' : 'flex-col'}`"
          >
            <!-- Back Arrow + User ID -->
            <div class="flex items-center mr-3">
              <div class="w-3 mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="3"
                  stroke="#265170"
                  class="w-6 h-6 cursor-pointer"
                  @click="workItems_store.navigateBack"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
              </div>
              <p
                id="quickViewScrollTarget"
                class="text-rev-3xl text-primary font-bold mx-3"
                :style="{ width: 'max-content' }"
              >
                {{ workItems_store?.workItem?.patient_name }}
              </p>

              <div
                v-if="workItems_store?.workItem?.deactivated == true"
                class="w-3"
              >
                <img
                  title="Deactivated"
                  :src="YellowExclamationPoint"
                  class="h-[32px]"
                />
              </div>
              <div
                v-else-if="workItems_store?.workItem?.is_priority == true"
                class="w-6"
              >
                <img title="Priority" :src="Priority" class="h-[32px]" />
              </div>
            </div>

            <!-- Workflow Status + Assignment -->
            <div class="flex w-full items-center text-rev-sm">
              <!-- Workflow Status -->
              <Popper
                :hover="true"
                :locked="true"
                placement="right"
                :disabled="getWorkflowStatus() !== 'Inactive'"
              >
                <div
                  v-show="getWorkflowStatus()"
                  class="mr-4 w-auto bg-yellow-one p-1 text-black rounded leading-3 text-rev-sm"
                >
                  {{ getWorkflowStatus() }}
                </div>
                <template #content>
                  <div
                    slot="reference"
                    class="bg-white p-2 rounded font-thin text-rev-sm shadow max-w-[15em]"
                  >
                    This workitem was not ready to be processed this morning.
                  </div>
                </template>
              </Popper>

              <!-- Assignment -->
              <div
                v-show="workItems_store?.workItem?.assignee_id"
                class="flex items-center mr-4"
              >
                <UserPicture
                  :user="
                    users_store.getUserById(
                      workItems_store?.workItem?.assignee_id
                    )
                  "
                />
              </div>
            </div>
          </div>
          <div class="flex w-1/2 gap-3 justify-end">
            <OutlinedButtonFlowBite
              v-if="
                rbac_store.hasWriteRole('CHECK_CLAIM_STATUS') &&
                workItemCheckClaimStatus()
              "
              class="border-primary text-primary"
              override-size-class="px-3 py-1.5"
              :disabled="workItems_store.isClaimStatusLoading"
              @click="getClaimStatus"
              >Check Claim Status</OutlinedButtonFlowBite
            >
            <OutlinedButtonFlowBite
              v-if="
                rbac_store.isFeatureFlagEnabled('GENERATE_APPEAL') &&
                rbac_store.hasWriteRole('GENERATE_DOCUMENTS')
              "
              class="border-primary px-3 text-primary"
              override-size-class="px-3 py-1.5"
              :disabled="isGeneratingAppeal === true"
              @click="createNewDocument()"
              >Generate Appeal</OutlinedButtonFlowBite
            >
          </div>
        </div>
        <div class="flex flex-col">
          <div
            id="work-item-container"
            class="flex w-full"
            :class="`${
              leftoverSpace >= 1280 ? 'flex-row gap-3' : 'flex-col gap-6'
            }`"
          >
            <WorkItemDetails />

            <!-- RHS -->
            <div class="flex flex-col flex-grow">
              <ActivityActionAndNotes
                :add-to-wip="addToWip"
                :mark-complete="markComplete"
                :add-note="saveNoteToWorkItem"
                :activity-draft="currentActivityDraft"
                :disabled="workItems_store.editActivityModeEnabled"
                @action-selected="action => handleActionSelected(action)"
                @reason-selected="reason => handleReasonSelected(reason)"
                @update-note="
                  note => {
                    currentActivityDraft.note = note;
                  }
                "
                @remove-activity-draft="
                  () => {
                    removeActivityDraft(currentActivityDraft.work_item_id);
                  }
                "
              />
              <ActivityStream />
            </div>
          </div>
          <div class="flex flex-col w-fit mt-2 ml-2 mb-4">
            <SupportPopper />
            <div class="text-primary text-base pt-1">
              <b>RSN:</b>
              {{ rsn || 'INVALID RSN' }}
            </div>
          </div>
        </div>
      </div>
      <ChatAI
        v-if="rbac_store.isFeatureFlagEnabled('CHAT_AI')"
        :button-list="buttonList"
        :page="'workItemDetails'"
        :rsn="rsn"
      />

      <ModalComponentFlowBite modal_id="delete-activity-modal">
        <div class="relative p-4 bg-white rounded-lg shadow sm:p-5">
          <h1 class="text-lg font-semibold text-primary my-2 mx-2">
            Delete Note?
          </h1>
          <h2 class="text-sm font-medium text-gray-700 mx-2 mb-2">
            Are you sure you want to delete this note? This action will
            permanently remove the note, and cannot be undone.
          </h2>
          <div class="flex items-center space-x-4 mx-2 mb-2">
            <OutlinedButtonFlowBite
              id="deleteModalClose"
              class="focus:ring-2 font-semibold"
              :disabled="submittedDeleteActivity"
              @click="modalClose"
            >
              Cancel
            </OutlinedButtonFlowBite>
            <SolidButtonFlowBite
              id="deleteModalSubmit"
              class="focus:ring-2 p-3 font-normal"
              :disabled="submittedDeleteActivity"
              @click="handleDelete"
              >Delete Note</SolidButtonFlowBite
            >
          </div>
        </div>
      </ModalComponentFlowBite>
    </div>
  </PageView>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, provide } from 'vue';
import { useRoute } from 'vue-router';
import { useStorage } from '@vueuse/core';
import YellowExclamationPoint from '@/assets/yellow_exclamation_point.svg';
import Priority from '@/assets/priority.svg';
import Popper from 'vue3-popper';
import { toast } from 'vue3-toastify';
import { Modal } from 'flowbite';

import { useUsersStore } from '@/stores/useUsers';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import { useDocumentsStore } from '@/stores/useDocuments';
import { useSearchStore } from '@/stores/useSearch';
import { useRbacStore } from '@/stores/useRbac';
import { useMetrics } from '@/stores/useMetrics';

import router from '@/router';
import ChatAI from '@/components/ChatAI.vue';

import ActivityStream from './ActivityStream.vue';
import WorkItemDetails from './WorkItemDetails.vue';
import PageView from '../../components/PageVue.vue';

import { ACTIVITY_TYPES, toasts } from '@/utils/constants';
import UserPicture from '@/components/UserPicture.vue';
import UserAnalytics from '@/components/UserAnalytics.vue';

import { chatAIConfig } from '@/utils/constants';
import SupportPopper from '@/components/SupportPopper.vue';
import ActivityActionAndNotes from './ActivityActionAndNotes.vue';

import ModalComponentFlowBite from '@/components/ModalComponentFlowBite.vue';
import OutlinedButtonFlowBite from '@/components/buttons/OutlinedButtonFlowBite.vue';
import SolidButtonFlowBite from '@/components/buttons/SolidButtonFlowBite.vue';

/* stores */
const users_store = useUsersStore();
const route = useRoute();
const workItems_store = useWorkItemsStore();
const documents_store = useDocumentsStore();
const rbac_store = useRbacStore();
const metrics_store = useMetrics();

/* local state */
const rsn = ref('');
const featureFlagPass = ref(false);
const buttonList = ref([]);

/* generate appeal state */
const isGeneratingAppeal = ref(false);

/* ActivityActionAndNotes */
/** list of all current activity drafts across workitems */
const activityDrafts = useStorage('activityDrafts', [], sessionStorage);
const currentActivityDraft = ref(getActivityDraftForWorkItem(route.params?.id));

const search_store = useSearchStore();

/** deletion modal */
const modalActivityDeletionEl = ref(null);
const submittedDeleteActivity = ref(false);

// The search modal should only show when returning from the work item details page
onUnmounted(() => {
  if (search_store.justSearched === true) {
    search_store.setJustSearched(false);
  }
  workItems_store.reset();
});

/** edge case where user navigates from one workitem to another from search */
watch(route, async to => {
  await fetchWorkItemDetails();
});

onMounted(async () => {
  await fetchWorkItemDetails();
});

onMounted(() => {
  const details =
    chatAIConfig[process.env['VUE_APP_CLIENT_PREFIX']] ||
    chatAIConfig['default'];
  buttonList.value = details.workItemDetails;
});

/** delete activity modal setup */
onMounted(() => {
  const options = {
    backdrop: 'static',
  };

  const instanceOptions = {
    id: 'delete-activity-modal',
    override: true,
  };

  modalActivityDeletionEl.value = new Modal(
    document.getElementById('delete-activity-modal'),
    options,
    instanceOptions
  );
});

provide('deleteActivityModalControls', modalActivityDeletionEl);

const fetchWorkItemDetails = async () => {
  const workItemId = route.params?.id;
  currentActivityDraft.value = getActivityDraftForWorkItem(workItemId);
  rsn.value = workItemId;
  if (workItemId) {
    await workItems_store.fetchWorkItem(workItemId);
    featureFlagPass.value = rbac_store.isFeatureFlagEnabled('CHAT_AI');
    await workItems_store.fetchWorkItemActivities(workItemId);
    await workItems_store.fetchWorkItemCharges(workItemId);
    await workItems_store.fetchWorkItemTransactions(workItemId);
    await documents_store.fetchAssociatedDocuments(workItemId);
  }
};

const saveNoteToWorkItem = async () => {
  let errorMessage = !currentActivityDraft.value
    ? 'Note must not be blank.'
    : 'Note must be under 1000 characters';
  if (!currentActivityDraft.value) {
    toast.error(errorMessage, {
      autoClose: 5000,
    });
    return;
  }

  const workItemId = route.params.id;
  workItems_store.isLoading = true;
  try {
    await workItems_store.postActivity(
      workItemId,
      ACTIVITY_TYPES['NOTE'].id,
      ACTIVITY_TYPES['NOTE'].name,
      null,
      null,
      currentActivityDraft.value.note,
      null
    );

    workItems_store.navigateBack();
  } catch (error) {
    console.error(error);
  } finally {
    workItems_store.isLoading = false;
  }
};

const WORKFLOW_STATUS_MAP = {
  Ready: 'Assigned',
  WIP: 'WIP',
  Complete: 'Complete',
  Inactive: 'Inactive',
  Unassigned: 'Unassigned',
};

const workFlowStatus = ref(null);

const workItemCheckClaimStatus = () => {
  return workItems_store.workItem?.can_get_claim_status;
};

const getWorkflowStatus = () => {
  let possibleStatus = workItems_store?.workItem?.workflow_status;
  if (
    workItems_store?.workItem?.deactivated == true &&
    possibleStatus != 'WIP'
  ) {
    workFlowStatus.value = 'Inactive';
  } else {
    const workflow_status_key = workItems_store?.workItem?.workflow_status;
    workFlowStatus.value = WORKFLOW_STATUS_MAP[workflow_status_key];
  }
  return workFlowStatus.value;
};

const getClaimStatus = async () => {
  if (workItems_store.isClaimStatusLoading)
    toast.error('Claim status check in progress');
  else {
    workItems_store.isClaimStatusLoading = true;
    const workItemId = route.params.id;
    const { message, success } = await workItems_store.getClaimStatus(
      workItemId
    );
    if (success) {
      toast.success(message);
    }
  }
};

const createNewDocument = async () => {
  if (isGeneratingAppeal.value === false) {
    isGeneratingAppeal.value = true;
    try {
      const workItemId = route.params.id;
      await workItems_store.fetchWorkItem(workItemId);
      const items = { ...workItems_store.workItem };

      const userName = `${users_store.activeUser.first_name} ${users_store.activeUser.last_name}`;

      await documents_store.createNewDocument(items, userName);
      if (documents_store.errorMessage) {
        toast.error(documents_store?.errorMessage || toasts.DEFAULT_ERROR, {
          autoClose: 5000,
        });
        return;
      }
      router.push({
        path: `/document/${workItemId}`,
      });
    } catch (err) {
      console.error(err);
    } finally {
      isGeneratingAppeal.value = false;
    }
  }
};

const addToWip = async () => {
  try {
    const workItemId = route.params.id;

    const response = await workItems_store.setWorkItemStatus(workItemId, 'wip');
    const {
      data: { ua_id },
      message,
      success,
    } = response;

    if (!success) {
      throw new Error(message);
    }

    // Post work activity
    await workItems_store.postActivity(
      workItemId,
      ACTIVITY_TYPES['ADDED_TO_WIP'].id,
      ACTIVITY_TYPES['ADDED_TO_WIP'].name,
      currentActivityDraft.value.auxo_activity_subtype_id,
      currentActivityDraft.value.auxo_activity_subtype_label,
      currentActivityDraft.value.note,
      ua_id
    );

    await metrics_store.getUserMetrics();
  } catch (error) {
    console.error(error);
    toast.error(error.message || toasts.DEFAULT_ERROR, { autoClose: 5000 });
  } finally {
    // Navigate Back
    workItems_store.navigateBack();
  }
};

const markComplete = async () => {
  try {
    const workItemId = route.params.id;
    const searchResultIDs = search_store.searchResults.map(
      result => result.revology_system_number
    );
    const fromSearch = searchResultIDs.includes(parseInt(workItemId));

    const response = await workItems_store.setWorkItemStatus(
      workItemId,
      'complete',
      fromSearch
    );
    const {
      data: { ua_id },
      message,
      success,
    } = response;

    if (!success) {
      throw new Error(message);
    }

    // Post work activity
    await workItems_store.postActivity(
      workItemId,
      ACTIVITY_TYPES['COMPLETED'].id,
      ACTIVITY_TYPES['COMPLETED'].name,
      currentActivityDraft.value.auxo_activity_subtype_id,
      currentActivityDraft.value.auxo_activity_subtype_label,
      currentActivityDraft.value.note,
      ua_id
    );

    // Update stats
    await metrics_store.getUserMetrics();
  } catch (error) {
    console.error(error);
    toast.error(error.message || toasts.DEFAULT_ERROR, {
      autoClose: 5000,
    });
  } finally {
    // Navigate back
    workItems_store.navigateBack();
  }
};

/**
 * Finds the draft in activityDrafts that is associcated with work item user
 * is currently viewing
 *
 * If one doesn't exists it returns a fresh object to be stored
 * @param workItemId - currently viewed workitem id
 */
function getActivityDraftForWorkItem(workItemId) {
  return (
    activityDrafts.value.find(draft => draft.work_item_id === workItemId) || {
      work_item_id: workItemId,
      auxo_activity_type_id: null,
      auxo_activity_subtype_id: null,
      auxo_activity_subtype_label: '',
      note: '',
    }
  );
}

/**
 * Finds current workitem draft in activityDrafts and updates it with new information
 * @param updatedDraft
 */
function updateActivityDraft(updatedDraft) {
  const index = activityDrafts.value.findIndex(
    draft => draft.work_item_id === updatedDraft.work_item_id
  );
  if (index !== -1) {
    activityDrafts.value[index] = updatedDraft;
  } else {
    activityDrafts.value.push(updatedDraft);
  }
}

/**
 * When user submits we need to clear their draft from sessionStorage by removing it from
 * activityDraft
 */
function removeActivityDraft(workItemId) {
  activityDrafts.value = activityDrafts.value.filter(
    draft => draft.work_item_id !== workItemId
  );
}

const handleActionSelected = action => {
  // went from one action to another
  // clear the reason
  if (action.id !== currentActivityDraft.value.auxo_activity_type_id) {
    currentActivityDraft.value.auxo_activity_subtype_label = '';
    currentActivityDraft.value.auxo_activity_subtype_id = null;
  }
  currentActivityDraft.value.auxo_activity_type_id = action.id;
};

const handleReasonSelected = reason => {
  if (!reason?.name) {
    return;
  }
  currentActivityDraft.value.auxo_activity_subtype_label = reason.name;
  currentActivityDraft.value.auxo_activity_subtype_id =
    reason.activity_subtype_id;
};

/** Keeps current workItem draft up to date */
watch(
  currentActivityDraft,
  updatedDraft => {
    updateActivityDraft(updatedDraft);
  },
  { deep: true }
);

const modalClose = () => {
  modalActivityDeletionEl.value.hide();
};

const handleDelete = async () => {
  submittedDeleteActivity.value = true;
  await workItems_store.editWorkItemActivity(
    workItems_store.activityToDelete.activity_id,
    workItems_store.activityToDelete.note,
    2 // UpdateAuxoActivityType enum - remove
  );
  modalClose();
  submittedDeleteActivity.value = false;
  window.location.reload();
};

document.addEventListener('keydown', function (e) {
  if (modalActivityDeletionEl.value.isVisible()) {
    let submitButton = document.getElementById('deleteModalSubmit');
    let closeButton = document.getElementById('deleteModalClose');
    let active = document.activeElement;
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        modalActivityDeletionEl.value.hide();
        workItems_store.activityToDelete = {};
        break;
      case 'Tab':
        e.preventDefault();
        if (active.id !== submitButton.id && active.id !== closeButton.id) {
          closeButton.focus();
        }
        if (active.id === submitButton.id) {
          closeButton.focus();
        }
        if (active.id === closeButton.id) {
          submitButton.focus();
        }
    }
  }
});

//Resizable Chat window
const screenWidth = ref(window.innerWidth);
const leftoverSpace = ref(window.innerWidth);

const updateScreenWidth = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', updateScreenWidth);
});

//We need this to be here because its the parent element
provide('leftoverSpace', leftoverSpace);
provide('screenWidth', screenWidth);
</script>
