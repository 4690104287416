<template>
  <aside
    id="default-sidebar"
    class="pb-8 z-20 h-screen bg-primary text-white border-r border-gray-200 transition-all duration-300"
    :class="{ 'w-[22rem]': !collapsed, 'w-20': collapsed }"
    aria-label="Sidenav"
    @mouseenter="expandSidebar"
    @mouseleave="collapseSidebar"
  >
    <div
      class="flex flex-col justify-between overflow-y-hidden py-5 px-3 h-[95%] dark:bg-gray-800 dark:border-gray-700"
      :class="{ 'w-[18rem]': !collapsed, 'w-18': collapsed }"
    >
      <div>
        <ul class="space-y-2">
          <li>
            <div
              class="flex flex-row justify-between items-center p-2 text-base rounded-xl font-normal rounded-lg group"
            >
              <div class="flex flex-row w-16 h-9 gap-2 items-center">
                <img :src="AuxoIcon" class="w-9 h-9" />
                <div
                  v-show="!collapsed"
                  class="flex flex-col h-9 mt-[-2.75rem]"
                >
                  <span
                    class="text-4xl font-medium leading-[57px] tracking-wide"
                  >
                    auxo
                  </span>
                  <span
                    class="text-xs whitespace-nowrap mt-[-.8rem] font-light leading-5 tracking-wideset"
                  >
                    BY REVOLOGY
                  </span>
                </div>
              </div>
              <FlowbitePinnedIcon
                v-show="!collapsed"
                :pinned="pinned"
                @click="togglePinned()"
              />
            </div>
          </li>
          <SidebarGoalsFlowbite :collapsed="collapsed" />
          <SidebarSearchFlowbite :collapsed="collapsed" />
          <template v-for="navItem in topNavRoutes" :key="navItem.name">
            <SidebarItemFlowbite
              v-if="rbac_store.hasAccessToRoute(navItem.name)"
              :path="navItem.path"
              :name="navItem.sidebarName"
              :collapsed="collapsed"
              :icon="navItem.flowbiteIcon"
            />
          </template>
        </ul>
      </div>
      <div>
        <ul class="pt-5 mt-5 space-y-2 border-b border-white">
          <li>
            <a
              href="https://revology.atlassian.net/servicedesk/customer/portal/1/article/64192734"
              target="_blank"
              class="flex items-center p-2 mb-3 text-base font-normal rounded-lg transition duration-75 hover:bg-sky-400 group"
              :class="{ 'justify-center': collapsed }"
            >
              <FlowbiteSupportIcon />
              <span v-show="!collapsed" class="ml-3">Support</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="flex justify-center items-center pz-4 space-x-4 w-full pt-2.5 mb-4 lg:flex z-20"
    >
      <!-- These elements will be added later, adding the v-show false for now cause the styling is right
       and I would like to just remove them once we are going to add the profile section -->
      <UserPicture :user="users_store.activeUser" :disable-popper="true" />
      <span
        v-if="false && !collapsed"
        class="w-1 h-3 border-r border-white"
      ></span>
      <div
        v-show="!collapsed"
        class="inline-flex justify-center border-r-1 border-white rounded cursor-pointer hover:bg-sky-400"
        @click="signOut"
      >
        Logout
      </div>
    </div>
  </aside>
</template>
<script setup>
import { ref } from 'vue';
import { routes } from '../../router/index';
import AuxoIcon from '@/assets/auxo-icon.svg';
import FlowbiteSupportIcon from '@/assets/sidebar-icons/FlowbiteSupportIcon.vue';
import SidebarItemFlowbite from './SidebarItem-Flowbite.vue';
import SidebarGoalsFlowbite from './SidebarGoals-Flowbite.vue';
import SidebarSearchFlowbite from './SidebarSearch-Flowbite.vue';
import UserPicture from '../UserPicture.vue';
import { useRbacStore } from '@/stores/useRbac';
import { useUsersStore } from '@/stores/useUsers';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useSessionStorage } from '@vueuse/core';
import FlowbitePinnedIcon from '@/assets/sidebar-icons/FlowbitePinnedIcon.vue';

const rbac_store = useRbacStore();
const gameplan_store = useGamePlanStore();
const users_store = useUsersStore();

const pinned = useSessionStorage('pinnned', true);
const collapsed = ref(!pinned.value);
const stats = ref(false);

function togglePinned() {
  pinned.value = !pinned.value;
}

function collapseSidebar() {
  if (!pinned.value && !stats.value) {
    collapsed.value = true;
  }
}

function expandSidebar() {
  collapsed.value = false;
}

async function signOut() {
  if (gameplan_store.editMode) {
    gameplan_store.logoutModal = true;
    return;
  }
  pinned.value = true;
  users_store.logOut();
}

/**
 * Returns All Routes With Sidebar True
 * @returns - Routes With Sidebar True
 */
const getTopNavRoutes = async () => {
  return Promise.all(
    routes
      ?.filter(route => route.sidebarTop == true)
      .map(async route => {
        if (route.featureFlag != null) {
          const featureFlagPass = rbac_store.isFeatureFlagEnabled(
            route.featureFlag
          );
          if (!featureFlagPass) return null;
        }

        return route;
      })
  ).then(routes => routes.filter(v => v));
};
const topNavRoutes = await getTopNavRoutes();
</script>
